body{
  background-color: azure;
}

.App {
  position: relative;
  height: 100vh;
}

.button{
  border: none;
  background-color: orangered;
  font-family: myfont;
  border-radius: 10px;
  color: white;
  font-size: large;
}

.centerInScreen{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text{
  font-family: myfont;
}

.border{
  border-width: 5px;
  border-color: black;
  border-style: solid;
}

.div{
  width: 100%;
  height: 100%;
}


@font-face {
  font-family: myfont;
  src: url('./assets//fonts/font.ttf');
}